import { trackPromise } from 'react-promise-tracker';
import { appConfig } from '../Config/appConfig';
var globals = require('./../../config.js').globals;
var resourceId = __CLIENT_ID__;
var apimKey = appConfig.AppSettings.APIMKey;
export function httpGet(_httpClient, accessToken, url, onSuccessCallback, onErrorCallback) {
    trackPromise(_httpClient
        .get(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Authorization': "Bearer " + accessToken,
        },
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpPut(_httpClient, accessToken, url, onSuccessCallback, onErrorCallback, formData) {
    trackPromise(_httpClient
        .put(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Authorization': "Bearer " + accessToken,
        },
        data: formData,
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpPost(_httpClient, accessToken, url, onSuccessCallback, onErrorCallback, formData) {
    trackPromise(_httpClient
        .post(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Authorization': "Bearer " + accessToken,
        },
        data: formData,
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpDelete(_httpClient, accessToken, url, onSuccessCallback, onErrorCallback) {
    trackPromise(_httpClient
        .delete(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Authorization': "Bearer " + accessToken,
        },
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
