var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { ContextualMenu, DefaultButton, IconButton, Stack, PrimaryButton, TooltipHost, Dialog, DialogFooter, DialogType, } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import * as Styled from '../../shared/layout';
import { ComponentContext, withContext } from '@employee-experience/common';
import { useHistory, withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { httpGet, httpPut } from '../../shared/APICalls';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import DownloadFilesWithPrimaryButton from '../CommonUtilities/DownloadFilesWithPrimaryButton';
import { stackItemRequestContentStylesInPanel, stackItemRequestHeaderStylesInPanel } from './Styles';
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var appConfig = require('../../Config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var storageAccountName = appConfig.AppSettings.StorageAccountName;
var MyRequestDetails = function (props) {
    var _a = useState(''), requestId = _a[0], setRequestId = _a[1];
    var _b = useState(''), defaultRequestId = _b[0], setDefaultRequestId = _b[1];
    var titleId = useId('title');
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _c = useState([{
            requestID: Number,
            requestor: '',
            approver: '',
            sponsor: '',
            sponsors: '',
            requestDateTime: null,
            businessJustification: '',
            productName: '',
            signingType: '',
            signingDetail: '',
            serialNumber: '',
            firmwareDescription: '',
            firmwareVersion: '',
            fileInfoStr: '',
            statusCode: '',
            filesToSign: '',
            smokeTestFiles: '',
            signatureFiles: '',
            rejectReason: '',
        }]), requestDetails = _c[0], setRequestDetails = _c[1];
    var _d = useState([]), defaultRequestDetails = _d[0], setDefaultRequestDetails = _d[1];
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    var _f = useState([]), requestBlobMetadata = _f[0], setRequestBlobMetadata = _f[1];
    var _g = useState(['']), defaultRequestBlobMetadata = _g[0], setDefaultRequestBlobMetadata = _g[1];
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 50,
        padding: 10,
    };
    var history = useHistory();
    var calloutProps = { gapSpace: 0 };
    var hostStyles = { root: { display: 'inline-block' } };
    var tooltipId = useId('tooltip');
    var _h = React.useState(true), hideFileControls = _h[0], setHideFileControls = _h[1];
    var _j = React.useState(true), hideSignedFileControls = _j[0], setHideSignedFileControls = _j[1];
    var _k = React.useState(true), hideButtonCancel = _k[0], setHideButtonCancel = _k[1];
    var _l = React.useState(false), disableButtonCancel = _l[0], setDisableButtonCancel = _l[1];
    var _m = React.useState(true), hideRejectReasonControls = _m[0], setHideRejectReasonControls = _m[1];
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'My Requests',
            key: 'f2',
            onClick: function () {
                navigateToMyRequestsPage();
            },
        },
        {
            text: "View My Request Details - " + requestId,
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };
    var modalPropsStyles = { main: { maxWidth: 500 } };
    var cancelDialogContentProps = {
        type: DialogType.normal,
        title: 'Cancel Request',
        subText: 'Do you want to Cancel this request?',
    };
    var _o = useBoolean(true), hideCancelReqDialog = _o[0], toggleHideCancelReqDialog = _o[1].toggle;
    var _p = useBoolean(false), isDraggable = _p[0], toggleIsDraggable = _p[1].toggle;
    var modalProps = React.useMemo(function () { return ({
        isBlocking: true,
        styles: modalPropsStyles,
        dragOptions: isDraggable ? dragOptions : undefined,
    }); }, [isDraggable]);
    var _q = React.useState(''), statusDescription = _q[0], setStatusDescription = _q[1];
    var _r = React.useContext(ComponentContext), telemetryClient = _r.telemetryClient, authClient = _r.authClient;
    var _s = React.useState(''), loggedInUser = _s[0], setLoggedInUser = _s[1];
    var _t = useState(''), acccessToken = _t[0], setAcccessToken = _t[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
        });
    });
    useEffect(function () {
        setRequestId(props.requestIdData.toString());
    }, [props.requestIdData]);
    useEffect(function () {
        SearchResults(requestId);
    }, [requestId, loggedInUser, acccessToken]);
    var _u = React.useState(''), sasTokenReadOnly = _u[0], setSasTokenReadOnly = _u[1];
    useEffect(function () {
        if (requestId != "" && acccessToken != "") {
            var url = baseUrl + "/signing-approvals/GetSasByTypeAsync?requestId=" + requestId;
            httpGet(_httpClient, acccessToken, url, function (response) {
                setSasTokenReadOnly(response.data);
            }, function (error) {
                telemetryClient.trackException({
                    exception: error.data.Message,
                    properties: { 'Component Name': 'MyRequestDetails', 'Method Name': 'GetSasByTypeAsync', },
                });
            });
        }
    }, [requestId]);
    useEffect(function () {
        if (requestId != "" && acccessToken != "") {
            var url = baseUrl + "/signing-approvals/GetRequestBlobMetadata?requestId=" + requestId;
            httpGet(_httpClient, acccessToken, url, function (response) {
                setRequestBlobMetadata(response.data);
                var items = [];
                var itemStr = '';
                response.data.map(function (item, i) {
                    updateState("{ FileToSignName: " + item.fileToSignName + ", FileCreatedDate: " + item.fileCreatedDate + ",\n                                FileModifiedDate: " + item.FileModifiedDate + ", FileOwner: " + item.fileOwner + ", Machine: " + item.machine + ", FileToSignLength: " + item.FileToSignLength + " }; \n");
                });
            }, function (error) {
                telemetryClient.trackException({
                    exception: error.data.Message,
                    properties: { 'Component Name': 'MyRequestDetails', 'Method Name': 'GetRequestBlobMetadata', },
                });
            });
        }
    }, [requestId]);
    var updateState = function (newState) {
        setDefaultRequestBlobMetadata(function (defaultRequestBlobMetadata) { return defaultRequestBlobMetadata.concat(newState); });
    };
    var RequestAction = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (action == 'Cancel') {
                cancelSigningRequest(action);
            }
            return [2 /*return*/];
        });
    }); };
    var cancelSigningRequest = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (requestId != "" && action != "" && acccessToken != "") {
                url = baseUrl + "/signing-approvals/RequestAction?requestId=" + requestId + "&&approverAction=" + action;
                httpPut(_httpClient, acccessToken, url, function (response) {
                    toast.success("RequestID: " + requestId + " has been Cancelled successfully");
                    navigateToMyRequestsPage();
                }, function (error) {
                    toast.error("Error while Cancelling RequestId: " + requestId + " and error is :" + error.data);
                    navigateToMyRequestsPage();
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyRequestDetails', 'Method Name': 'cancelSigningRequest', },
                    });
                }, JSON.stringify({}));
            }
            return [2 /*return*/];
        });
    }); };
    var SetControlsFromRequestStatusInMyReqDetailsPage = function (response) {
        if (response.statusCode == 'Completed' || response.statusCode == 'Fulfilled' || response.statusCode == 'Processing' ||
            response.statusCode == 'Succeeded' || response.statusCode == 'Failed' || response.statusCode == 'Cancelled' ||
            response.statusCode == 'SponsorRejected' || response.statusCode == 'ApproverRejected') {
            setDisableButtonCancel(true);
        }
    };
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var navigateToMyRequestsPage = function () {
        history.push("/view-my-requests");
    };
    var navigateToMyRequestDetailsPage = function (requestID) {
        history.push("/my-request-details/" + requestID);
    };
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (searchId != "" && loggedInUser != "" && acccessToken != "") {
                url = baseUrl + "/signing-approvals/GetSigningRequestDetails?requestId=" + searchId;
                httpGet(_httpClient, acccessToken, url, function (response) {
                    if (loggedInUser == response.data[0].requestorEmail) {
                        setRequestDetails(response.data);
                        setDefaultRequestDetails(response.data);
                        if (response.data[0].filesToSign != "") {
                            setHideFileControls(false);
                        }
                        if (response.data[0].statusCode == "Fulfilled") {
                            setHideSignedFileControls(false);
                        }
                        if (response.data[0].statusCode == "SponsorRejected" || response.data[0].statusCode == "ApproverRejected") {
                            setHideRejectReasonControls(false);
                        }
                        SetControlsFromRequestStatusInMyReqDetailsPage(response.data[0]);
                    }
                    else {
                        toast.error("RequestID: " + searchId + " isn't created by you. You can find requests created by others from home page top search feature");
                    }
                }, function (error) {
                    toast.error("Error while fetching RequestID: " + searchId + " and error is " + error.data.Message);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyRequestDetails', 'Method Name': 'SearchResults', },
                    });
                });
            }
            return [2 /*return*/];
        });
    }); };
    var onClickGetDataHandler = function (event) {
        if (event.target.value.length > 0) {
            setRequestId(event.target.value);
            navigateToMyRequestDetailsPage(event.target.value);
        }
        else {
            toast.error("Please ensure Proper search criteria in Searchbox");
        }
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    return (React.createElement(Styled.Container, null,
        React.createElement(Stack, null,
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " RequestID: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].requestID))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Requestor: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].requestor))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Sponsor(s): \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].sponsors.toString()))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Request Date Time (UTC): \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].requestDateTime && new Date(requestDetails[0].requestDateTime).toLocaleString()))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Business Justification: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].businessJustification))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Product: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].productName))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Signing Type: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].signingType))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Signing Detail: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].signingDetail))),
            requestDetails[0].serialNumber && React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Serial Number: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].serialNumber))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Software/Firmware Version: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].firmwareVersion))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Software/Firmware Description: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].firmwareDescription))),
            !hideFileControls && requestDetails[0].filesToSign && React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " File(s) To Sign: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Stack, null,
                        React.createElement(Stack.Item, null,
                            React.createElement(Text, { variant: 'medium' }, requestDetails[0].filesToSign)),
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Stack.Item, null,
                                React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "FilesToSign/", text: "Input Files", fileTypeId: "FILE_TO_SIGN" })),
                            React.createElement(Stack.Item, null,
                                React.createElement("div", null,
                                    React.createElement(TooltipHost, { content: defaultRequestBlobMetadata, id: tooltipId, calloutProps: calloutProps, styles: hostStyles },
                                        React.createElement(IconButton, { title: 'FileProps', iconProps: { iconName: 'Info' }, "aria-describedby": tooltipId })))))))),
            !hideFileControls && requestDetails[0].smokeTestFiles && React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, "Smoke Test File: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Stack, null,
                        React.createElement(Stack.Item, null,
                            React.createElement(Text, { variant: 'medium' }, requestDetails[0].smokeTestFiles)),
                        React.createElement(Stack.Item, null,
                            React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "SmokeTestResult/", text: "SmokeTest Files", fileTypeId: "SMOKE_TEST_RESULT" }))))),
            !hideSignedFileControls && requestDetails[0].signatureFiles && React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Signature File: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Stack, null,
                        React.createElement(Stack.Item, null,
                            React.createElement(Text, { variant: 'medium' }, requestDetails[0].signatureFiles)),
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Stack.Item, null,
                                React.createElement(DownloadFilesWithPrimaryButton, { requestId: requestDetails[0].requestID, blobPrefix: "SignedFiles/", text: "Signature Files", fileTypeId: "SIGNED_FILE" })))))),
            React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Signing Request Status: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].statusCode))),
            !hideRejectReasonControls && React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true },
                        " ",
                        React.createElement("strong", null, " Request Reject Reasson: \u2003"))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStylesInPanel },
                    React.createElement(Text, { variant: 'medium', block: true }, requestDetails[0].rejectReason)))),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            !disableButtonCancel && React.createElement(PrimaryButton, { secondaryText: "Cancel Request", onClick: toggleHideCancelReqDialog, text: "Cancel Request" }),
            React.createElement(Dialog, { hidden: hideCancelReqDialog, onDismiss: toggleHideCancelReqDialog, dialogContentProps: cancelDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            toggleHideCancelReqDialog();
                            RequestAction("Cancel");
                        }, text: "Okay, Cancel Request" }),
                    React.createElement(DefaultButton, { onClick: toggleHideCancelReqDialog, text: "Don't Cancel" }))))));
};
var connected = withContext(withRouter(MyRequestDetails));
export { connected as MyRequestDetails };
