var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var Initia_Sign_Validations = {
    sponsorsError: '',
    businessJustificationError: '',
    mailCcError: '',
    signingTypeError: "",
    serialNumberError: "",
    artifactVersionError: "",
    filesToSignError: "",
    smokeTestResultError: "",
    isFormValid: null
};
export var signFormValidationReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'onValidationError': return __assign(__assign({}, state), (_a = { isFormValid: state.isFormValid == null ? action.payload.value == "" : state.isFormValid && action.payload.value == "" }, _a[action.payload.name] = action.payload.value, _a));
        case 'onReset': return Initia_Sign_Validations;
        default: return state;
    }
};
