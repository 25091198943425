import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { Fabric, PrimaryButton, Breadcrumb, addYears, addDays, addMonths, } from '@fluentui/react';
import { DatePicker, DayOfWeek } from '@fluentui/react/lib/DatePicker';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import SearchResultsData from './SearchResultsData';
import { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { ComponentContext } from '@employee-experience/common';
import { controlClass, controlStyles, dropdownStyles, stackTokens } from './AdvancedSearchStyles';
var appConfig = require('../../Config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var today = new Date(Date.now());
var minDate = addYears(today, -10);
var maxDate = addMonths(today, 0);
function AdvancedSearch() {
    var _a = React.useContext(ComponentContext), telemetryClient = _a.telemetryClient, authClient = _a.authClient;
    var context = React.useContext(ComponentContext);
    var history = useHistory();
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _b = React.useState(''), loggedInUser = _b[0], setLoggedInUser = _b[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
        });
    });
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'Manage Signing Requests',
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var DayPickerStrings = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Field is required.',
        invalidInputErrorMessage: 'Invalid date format.',
        isOutOfBoundsErrorMessage: "Date must be between " + minDate.toLocaleDateString() + "-" + maxDate.toLocaleDateString(),
    };
    var _c = useState({
        searchId: '',
        fromDate: null,
        toDate: null,
        signingType: '',
        loggedInUser: '',
    }), gridFilteringData = _c[0], setGridFilteringData = _c[1];
    var _d = useState(sessionStorage.getItem('fromDate') ? new Date(sessionStorage.getItem('fromDate')) : addMonths(today, -2)), fromDate = _d[0], setFromDate = _d[1];
    var _e = useState(sessionStorage.getItem('toDate') ? new Date(sessionStorage.getItem('toDate')) : addDays(today, 0)), toDate = _e[0], setToDate = _e[1];
    var _f = React.useState([]), signingTypeOptions = _f[0], setSigningTypeOptions = _f[1];
    var _g = useState(sessionStorage.getItem('signingType') || 'Test Playready Signer (FW)'), signingType = _g[0], setSigningType = _g[1];
    React.useEffect(function () {
        sessionStorage.setItem('fromDate', fromDate.toString());
        sessionStorage.setItem('toDate', toDate.toString());
        sessionStorage.setItem('companyCode', signingType);
    }, [fromDate, toDate, signingType]);
    var onSelectFromDate = function (date) {
        setFromDate(date);
    };
    var onSelectToDate = function (date) {
        setToDate(date);
    };
    var onSelectSigningType = function (event, item) {
        setSigningType(item.text);
    };
    useEffect(function () {
        setGridFilteringData({
            searchId: '',
            fromDate: null,
            toDate: null,
            signingType: '',
            loggedInUser: loggedInUser,
        });
    }, [loggedInUser]);
    var firstDayOfWeek = DayOfWeek.Sunday;
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var setFilters = function () {
        setGridFilteringData({
            searchId: sessionStorage.getItem('searchText'),
            fromDate: dateToISOLikeButLocal(fromDate),
            toDate: dateToISOLikeButLocal(toDate),
            signingType: signingType,
            loggedInUser: loggedInUser,
        });
    };
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    return (React.createElement(Styled.Container, null,
        React.createElement(Fabric, null,
            React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }),
            React.createElement("div", null,
                React.createElement("br", null)),
            React.createElement("div", null,
                React.createElement(Stack, { horizontalAlign: "space-between", horizontal: true, tokens: stackTokens },
                    React.createElement(Stack, { horizontalAlign: "start", horizontal: true, tokens: stackTokens },
                        React.createElement(DatePicker, { className: controlClass.control, label: "From Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a date", value: fromDate, ariaLabel: "Select a date", minDate: minDate, maxDate: maxDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectFromDate }),
                        React.createElement(DatePicker, { className: controlClass.control, label: "To Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a data", value: toDate, ariaLabel: "Select a date", minDate: minDate, maxDate: maxDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectToDate }),
                        React.createElement(Dropdown, { placeholder: "Select the Signing Type", label: "Signing Type:", options: signingTypeOptions, styles: dropdownStyles, selectedKey: signingType, onChange: onSelectSigningType }),
                        React.createElement(PrimaryButton, { text: "Search", className: controlClass.searchStyles, onClick: setFilters })))),
            React.createElement("div", null,
                React.createElement(SearchResultsData, { gridFilteringData: gridFilteringData })))));
}
var connected = withContext(withRouter(AdvancedSearch));
export { connected as AdvancedSearch };
