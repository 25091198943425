import axios from "axios";
import { appConfig } from "../Config/appConfig";
export var fetchHttpClient = function (accessToken) {
    var instance = axios.create({
        baseURL: "" + appConfig.AppSettings.APIUrl,
        headers: {
            "Content-type": "application/json",
            'X-Content-Type-Options': 'nosniff',
            'Authorization': "Bearer " + accessToken
        }
    });
    return instance;
};
