import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import { DefaultPalette } from '@fluentui/react';
import { withRouter } from 'react-router-dom';
import { ComponentContext } from '@employee-experience/common';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var appConfig = require('../../Config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
//TODO:VR -> Unused
var itemStyles = {
    alignItems: 'center',
    background: DefaultPalette.yellow,
    color: DefaultPalette.white,
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
};
var sectionStackTokens = { childrenGap: 1 };
var wrapStackTokens = { childrenGap: 25 };
function Dashboard() {
    var history = useHistory();
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var telemetryClient = React.useContext(ComponentContext).telemetryClient;
    var _a = React.useState(100), stackWidth = _a[0], setStackWidth = _a[1];
    // Mutating styles definition
    var stackStyles = {
        root: {
            background: DefaultPalette.white,
            width: stackWidth + "%",
        },
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    // const { telemetryClient, authClient } = React.useContext(ComponentContext);
    // const [loggedInUser, setLoggedInUser] = React.useState('');
    // const [acccessToken, setAcccessToken] = React.useState('');
    // useEffect(() => {
    //     authClient.getUserId().then((res: any) => {
    //         setLoggedInUser(res);
    //     });
    //     authClient.acquireToken(__CLIENT_API_SCOPES__).then((res: any) => {
    //         setAcccessToken(res);
    //     });
    // });
    var navigateToSigningRequestDetailsPage = function (requestId) {
        history.push("/signing-request-details/" + requestId);
    };
    var onClickGetDataHandler = function (event) {
        var metricData = {
            name: 'Top Search',
        };
        var additionalProperties = { 'Component Name': 'Dashboard' };
        if (event.target.value.length > 0) {
            telemetryClient.trackMetric(metricData, additionalProperties);
            navigateToSigningRequestDetailsPage(event.target.value);
        }
        else {
            toast.error("Please enter valid RequestId as search criteria in Search box");
        }
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    return (React.createElement("div", { className: "app" },
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("h1", { className: "dashboard_header", style: { textAlign: 'center' } }, " Welcome to modern AutoSign Portal!"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement("p", null, "This is your new modern AutoSign portal for all the signing needs."),
            React.createElement("br", null),
            React.createElement("p", null, "We have rolled out \u2018Submission\u2019 of signing requests and new sign requests can now be created from this portal."),
            React.createElement("br", null),
            React.createElement("p", null,
                "We will be retiring the legacy AutoSign portal at\u00A0",
                React.createElement("a", { target: "_blank", href: "http://autosign/request/default.aspx" }, "http://autosign"),
                "\u00A0(requires Corpnet) on ",
                React.createElement("i", null, "31st Jan 2023"),
                ". Request you to use AutoSign 2.0 for all your signing needs."),
            React.createElement("br", null),
            React.createElement("p", null, "This portal is 100% accessible and brings-in rich user experience with fluent design along with new features."))));
}
var connected = withContext(withRouter(Dashboard));
export { connected as Dashboard };
