var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Text } from '@fluentui/react/lib/Text';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: 1%;\n    margin-bottom: 48px;\n    margin-left: 1%;\n    margin-right: 5%;\n"], ["\n    margin-top: 1%;\n    margin-bottom: 48px;\n    margin-left: 1%;\n    margin-right: 5%;\n"])));
export var Space = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 24px;\n"], ["\n    margin-bottom: 24px;\n"])));
export var PageHeading = styled(Text).attrs({
    as: 'h1',
    variant: 'xLarge',
    block: true
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-bottom: 6px;\n"], ["\n    margin-bottom: 6px;\n"])));
export var PageDescription = styled(Text).attrs({
    as: 'p',
    block: true
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-bottom: 24px;\n"], ["\n    margin-bottom: 24px;\n"])));
export var SectionTitle = styled(Text).attrs({
    as: 'h2',
    variant: 'large',
    block: true
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-bottom: 24px;\n"], ["\n    margin-bottom: 24px;\n"])));
export var listContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding-left: 8px;\n    margin-top: 24px;\n    margin-bottom: 24px;\n"], ["\n    padding-left: 8px;\n    margin-top: 24px;\n    margin-bottom: 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
