export var signingTypeValidator = function (signingType) {
    if (!signingType) {
        return "Please provide Signing Type";
    }
    return "";
};
export var businessJustificationValidator = function (businessJustification) {
    if (!businessJustification) {
        return "Please provide business justification.";
    }
    return "";
};
export var sponsorsValidator = function (isSponsorRequired, sponsors) {
    if (!isSponsorRequired)
        return "";
    if (isSponsorRequired && (!sponsors || sponsors.length <= 0)) {
        return "FTE Sponsor(s) required.";
    }
    else if (!(sponsors === null || sponsors === void 0 ? void 0 : sponsors.every(function (s) { return isValidEmail(s); }))) {
        return "Please provide valid sponsors.";
    }
    else if (!(sponsors === null || sponsors === void 0 ? void 0 : sponsors.every(function (s) { return !isNonFTE(s); }))) {
        return "Please provide FTE(s) as sponsors.";
    }
    else if (lengthOfMails(sponsors) > 1280) {
        return "Sponsor string is too large. It should not be more than 1280.";
    }
    return "";
};
export var serialNumberValidator = function (serialNumber, signingTemplate, signingType) {
    var serialNumberRestrictionsFor = [
        "TEST_DBGPOLICYAND_CRASHDUMP",
        "TEST_DBGPOLICYAND",
        "TEST_DBGPOLICYAND_CRASHDUMP_V1",
        "TEST_DBGPOLICYAND_V1",
        "TEST_DBGPOLICYAND_CRASHDUMP_V2",
        "TEST_DBGPOLICYAND_V2",
        "TEST_DBGPOLICYAND_CRASHDUMP_V3",
        "TEST_DBGPOLICYAND_V3",
        "TEST_LOCK_DBGPOLICYAND_V0",
        "TEST_LOCK_DBGPOLICYAND_V1",
        "TEST_LOCK_DBGPOLICYAND_V2",
        "TEST_LOCK_DBGPOLICYAND_V3",
        "DBGPOLICYAND_CRASHDUMP_V0",
        "DBGPOLICYAND_V0",
        "DBGPOLICYAND_CRASHDUMP_V1",
        "DBGPOLICYAND_V1",
        "DBGPOLICYAND_CRASHDUMP_V2",
        "DBGPOLICYAND_V2",
        "DBGPOLICYAND_CRASHDUMP_V3",
        "DBGPOLICYAND_V3",
        "LOCK_DBGPOLICYAND_V0",
        "LOCK_DBGPOLICYAND_V1",
        "LOCK_DBGPOLICYAND_V2",
        "LOCK_DBGPOLICYAND_V3",
        "TEST_DBGPOLICY_ARTHUR_CRASHDUMP",
        "TEST_DBGPOLICY_ARTHUR",
        "DBGPOLICYHL2_CRASHDUMP_V0",
        "DBGPOLICYHL2_V0",
        "DBGPOLICYHL2_CRASHDUMP_V1",
        "DBGPOLICYHL2_V1",
        "DBGPOLICYHL2_CRASHDUMP_V2",
        "DBGPOLICYHL2_V2",
        "DBGPOLICYHL2_CRASHDUMP_V3",
        "DBGPOLICYHL2_V3",
        "LOCK_DBGPOLICYHL2_V0",
        "LOCK_DBGPOLICYHL2_V1",
        "LOCK_DBGPOLICYHL2_V2",
        "LOCK_DBGPOLICYHL2_V3",
        "TEST_DBGPOLICYCAMBRIA_CRASHDUMP_V0",
        "TEST_DBGPOLICYCAMBRIA_V0",
        "TEST_DBGPOLICYCAMBRIA_CRASHDUMP_V1",
        "TEST_DBGPOLICYCAMBRIA_V1",
        "TEST_DBGPOLICYCAMBRIA_CRASHDUMP_V2",
        "TEST_DBGPOLICYCAMBRIA_V2",
        "TEST_DBGPOLICYCAMBRIA_CRASHDUMP_V3",
        "TEST_DBGPOLICYCAMBRIA_V3",
        "DBGPOLICYCAMBRIA_CRASHDUMP_V0",
        "DBGPOLICYCAMBRIA_V0",
        "DBGPOLICYCAMBRIA_CRASHDUMP_V1",
        "DBGPOLICYCAMBRIA_V1",
        "DBGPOLICYCAMBRIA_CRASHDUMP_V2",
        "DBGPOLICYCAMBRIA_V2",
        "DBGPOLICYCAMBRIA_CRASHDUMP_V3",
        "DBGPOLICYCAMBRIA_V3"
    ];
    if (!isSerialNumberRequired(signingTemplate))
        return "";
    if (!serialNumber) {
        return "Please provide Serial Number of the device.";
    }
    if (contains(serialNumberRestrictionsFor, signingType) && (serialNumber === null || serialNumber === void 0 ? void 0 : serialNumber.length) != 8 && !/^[0-9A-F]+$/i.test(serialNumber)) //regex for hex
     {
        return "A valid serial number should not include 0x and it must be of 8 character long valid hex string.";
    }
    return "";
};
export var artifactVersionValidator = function (artifactVersion, signingType) {
    if (isArtifactVersionRequired(signingType) && !artifactVersion) {
        return "Please provide a Firmware Version.";
    }
    return "";
};
export var filesToSignValidator = function (isInputFileRequired, filesToSign) {
    if (isInputFileRequired && filesToSign.length <= 0) {
        return "Please provide files to sign.";
    }
    return "";
};
export var smokeTestResultValidator = function (signingTemplate, signingType, smokeTestFiles) {
    if (isSmokeTestResultRequired(signingTemplate, signingType) && smokeTestFiles.length <= 0) {
        return "Please provide smoke test result.";
    }
    return "";
};
export var isSerialNumberRequired = function (signingTemplate) {
    if (!signingTemplate)
        return false;
    return signingTemplate.localeCompare('DBG', undefined, { sensitivity: 'base' }) === 0;
};
export var isSmokeTestResultRequired = function (signingTemplate, signingType) {
    var smokeTestResultNotRequiredFor = ["MFFUEFI", "MFFUEFI64"];
    if (!signingTemplate || !signingType)
        return false;
    return signingTemplate.localeCompare('FW', undefined, { sensitivity: 'base' }) === 0 && !contains(smokeTestResultNotRequiredFor, signingType);
};
export var isArtifactVersionRequired = function (signingType) {
    var artifactVersionRequiredFor = ["MFFUEFI", "MFFUEFI64"];
    if (!signingType)
        return false;
    return contains(artifactVersionRequiredFor, signingType);
};
export var isValidEmail = function (email) {
    return /\S+@\S+\.\S+/.test(email);
};
export var lengthOfMails = function (arr) {
    var sum = arr.length - 1; //keeping count for semicolon between emails
    arr.map(function (i) { return sum = sum + i.length; });
    return sum;
};
export var isNonFTE = function (user) {
    var nonFteReqExp = new RegExp('^[a-zA-Z]-');
    return nonFteReqExp.test(user);
};
export var contains = function (arr, searchStr) {
    return arr.some(function (e) { return searchStr.includes(e); });
};
