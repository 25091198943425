var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { ConstrainMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList';
import { useState, useEffect } from 'react';
import { DetailsList } from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { Panel, PanelType, Stack, Fabric, TooltipHost, SelectionMode, ScrollablePane, Breadcrumb, Sticky, StickyPositionType, Link, PrimaryButton, DefaultButton, ContextualMenu, Dialog, DialogFooter, DialogType, initializeIcons, FontIcon, CommandBarButton, SearchBox } from '@fluentui/react';
import { toast } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import * as Styled from '../../shared/layout';
import { useHistory } from 'react-router-dom';
import { MyRequestDetails } from './MyRequestDetails';
import { httpGet, httpPut } from '../../shared/APICalls';
import CreateSigningRequest from './CreateSigningRequest';
import { useBoolean } from '@uifabric/react-hooks';
import DownloadFiles from '../CommonUtilities/DownloadFiles';
import { stackItemHeadingStyles, stackItemRefreshStyles, stackItemSearchBoxStyles, stackStyles, statusIconCommnonStyles, statusIconStyles, tabLeftStackTokens, tabRighStackTokens } from './Styles';
var appConfig = require('../../Config/appConfig.js').appConfig;
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
var baseUrl = appConfig.AppSettings.APIUrl;
var ViewMyRequests = function (props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useBoolean(false), isOpenForCreate = _b[0], _c = _b[1], openPanelForCreate = _c.setTrue, dismissPanelForCreate = _c.setFalse;
    var _d = useBoolean(false), isOpenToVeiewRequest = _d[0], _e = _d[1], openPanelToVeiewRequest = _e.setTrue, dismissPanelToVeiewRequest = _e.setFalse;
    var _f = useState(0), requestIdData = _f[0], setRequestIdData = _f[1];
    var _g = useState(''), requestId = _g[0], setRequestId = _g[1];
    var _h = useState(''), requestorAliasData = _h[0], setRequestorAliasData = _h[1];
    var _j = useState(sessionStorage.getItem('searchText') || ''), searchText = _j[0], setSearchText = _j[1];
    React.useEffect(function () {
        sessionStorage.setItem('searchText', searchText);
    }, [searchText]);
    var _k = useState([]), columns = _k[0], setColumns = _k[1];
    var _l = useState([]), myRequests = _l[0], setMyRequests = _l[1];
    var _m = useState([]), defaultMyRequests = _m[0], setDefaultMyRequests = _m[1];
    var lastStatusIcons = { marginTop: -7 };
    var lastStatusCancelIcons = { marginTop: -7, backgroundColor: '#ffebee' };
    var filterIcon = { iconName: 'Filter' };
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'My Requests',
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    initializeIcons();
    var dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };
    var modalPropsStyles = { main: { maxWidth: 500 } };
    var cancelDialogContentProps = {
        type: DialogType.normal,
        title: 'Cancel Request',
        subText: 'Do you want to Cancel this request?',
    };
    var _o = useBoolean(true), hideCancelReqDialog = _o[0], toggleHideCancelReqDialog = _o[1].toggle;
    var _p = useBoolean(false), isDraggable = _p[0], toggleIsDraggable = _p[1].toggle;
    var modalProps = React.useMemo(function () { return ({
        isBlocking: true,
        styles: modalPropsStyles,
        dragOptions: isDraggable ? dragOptions : undefined,
    }); }, [isDraggable]);
    var _q = React.useContext(ComponentContext), telemetryClient = _q.telemetryClient, authClient = _q.authClient;
    var _r = React.useState(''), loggedInUser = _r[0], setLoggedInUser = _r[1];
    var _s = useState(''), acccessToken = _s[0], setAcccessToken = _s[1];
    useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res);
            telemetryClient.trackTrace({
                message: "Successfully retrived loggedin user details",
                properties: { 'Component Name': 'MyRequests', 'Method Name': 'getUserId', },
            });
        });
        authClient.acquireToken(__CLIENT_API_SCOPES__).then(function (res) {
            setAcccessToken(res);
            console.log(res);
        });
    });
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    var _itemsCommandBar = [
        // {
        //     key: 'newItem',
        //     text: "New Signing Request",
        //     cacheKey: 'myCacheKey',
        //     iconProps: { iconName: 'Add' },
        //     onClick: () => {
        //         openPanelForCreate();
        //         setRequestorAliasData(loggedInUser);
        //     },
        // },
        {
            key: 'Refresh',
            text: "Refresh",
            cacheKey: 'myCacheKey',
            iconProps: { iconName: 'Refresh' },
            onClick: function () {
                Refresh();
            },
        },
    ];
    useEffect(function () {
        GetAllRequestsByRequestor();
    }, [loggedInUser, acccessToken]);
    var refresh = { iconName: 'Refresh' };
    var newRequest = { iconName: 'Add' };
    var Refresh = function () {
        GetAllRequestsByRequestor();
    };
    var handleColumnClick = function (ev, column) {
        var newColumns = columns.slice();
        var currColumn = newColumns.filter(function (currCol) { return column.key === currCol.key; })[0];
        newColumns.forEach(function (newCol) {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        var newItems = _copyAndSort(_items, currColumn.fieldName, currColumn.isSortedDescending);
        setColumns(newColumns);
    };
    var column = [
        {
            key: 'requestID',
            name: 'RequestID',
            ariaLabel: 'requestID',
            fieldName: 'requestID',
            isResizable: true,
            minWidth: 80,
            maxWidth: 80,
            onRender: function (item) { return (React.createElement(Link, { onClick: function () {
                    openPanelToVeiewRequest();
                    setRequestIdData(item.requestID);
                } }, item.requestID)); }
        },
        {
            key: 'requestor',
            name: 'Requestor',
            ariaLabel: 'requestor',
            fieldName: 'requestor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 120,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.requestor); },
        },
        {
            key: 'sponsor',
            name: 'Sponsor',
            ariaLabel: 'sponsor',
            fieldName: 'sponsor',
            isResizable: true,
            minWidth: 100,
            maxWidth: 120,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.sponsor); },
        },
        {
            key: 'approver',
            name: 'Approver',
            ariaLabel: 'approver',
            fieldName: 'approver',
            isResizable: true,
            minWidth: 100,
            maxWidth: 120,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.approver); },
        },
        {
            key: 'businessJustification',
            name: 'Business Justification',
            ariaLabel: 'businessJustification',
            fieldName: 'businessJustification',
            isResizable: true,
            minWidth: 100,
            maxWidth: 250,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.businessJustification); },
        },
        {
            key: 'productName',
            name: 'Product Name',
            ariaLabel: 'productName',
            fieldName: 'productName',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.productName); },
        },
        {
            key: 'signingType',
            name: 'Signing Type',
            ariaLabel: 'signingType',
            fieldName: 'signingType',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingType); },
        },
        {
            key: 'signingDetail',
            name: 'Signing Detail',
            ariaLabel: 'signingDetail',
            fieldName: 'signingDetail',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.signingDetail); },
        },
        {
            key: 'statusCode',
            name: 'Status',
            ariaLabel: 'statusCode',
            fieldName: 'statusCode',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            data: 'string',
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement("span", { hidden: item.statusCode != 'Processing' && item.statusCode != 'Preparing' && item.statusCode != 'Initialized' },
                    React.createElement(FontIcon, { "aria-label": "Processing", iconName: "ProgressRingDots", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'Succeeded' && item.statusCode != 'Approved' && item.statusCode != 'SponsorApprovedProcessing' },
                    React.createElement(FontIcon, { "aria-label": "Succeeded", iconName: "CompletedSolid", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'Failed' },
                    React.createElement(FontIcon, { "aria-label": "Failed", iconName: "ErrorBadge", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Cancelled' && item.statusCode != 'MarkedApproverRejected' && item.statusCode != 'ApproverRejected' && item.statusCode != 'SponsorRejected' },
                    React.createElement(FontIcon, { "aria-label": "Cancelled", iconName: "Cancel", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'INDELAY' },
                    React.createElement(FontIcon, { "aria-label": "InDelay", iconName: "CirclePause", className: statusIconStyles.failedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'Completed' && item.statusCode != 'Fulfilled' },
                    React.createElement(FontIcon, { "aria-label": "Completed", iconName: "Completed", className: statusIconStyles.completedIcon })),
                React.createElement("span", { hidden: item.statusCode != 'New' },
                    React.createElement(FontIcon, { "aria-label": "New", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", { hidden: item.statusCode != 'AwaitingApproval' && item.statusCode != 'AwaitingSponsorApproval' && item.statusCode != 'AwaitingSignatureUpload' },
                    React.createElement(FontIcon, { "aria-label": "New", iconName: "NewAnalyticsQuery", className: statusIconCommnonStyles })),
                React.createElement("span", null, item.statusCode))); }
        },
        // {
        //     key: 'rejectReason',
        //     name: 'Reject Reason',
        //     ariaLabel: 'rejectReason',
        //     fieldName: 'rejectReason',
        //     isResizable: true,
        //     minWidth: 100,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.rejectReason}</span>,
        // },
        {
            key: 'action',
            name: 'Actions',
            ariaLabel: 'Actions',
            fieldName: 'action',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            onRender: function (item) { return (React.createElement("div", null,
                React.createElement(DownloadFiles, { requestId: item.requestID, blobPrefix: "SignedFiles/", disabled: item.statusCode != 'Fulfilled', title: "Download Signature Files", fileTypeId: "SIGNED_FILE" }),
                React.createElement(IconButton, { disabled: item.statusCode == 'Completed' || item.statusCode == 'Fulfilled' || item.statusCode == 'Processing' ||
                        item.statusCode == 'Succeeded' || item.statusCode == 'Failed' || item.statusCode == 'Cancelled' ||
                        item.statusCode == 'SponsorRejected' || item.statusCode == 'ApproverRejected', onClick: function () {
                        toggleHideCancelReqDialog();
                        setRequestId(item.requestID);
                    }, iconProps: { iconName: 'Cancel' }, title: 'Cancel Request' }))); },
        },
    ];
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var navigateToMyRequestDetailsPage = function (requestID) {
        history.push("/my-request-details/" + requestID);
    };
    var navigateToNewRequestPage = function () {
        history.push("/submit-signing-request");
    };
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var userAlias, url;
        return __generator(this, function (_a) {
            userAlias = loggedInUser.split('@')[0];
            if (searchId != "" && loggedInUser != "" && acccessToken != "") {
                url = baseUrl + "/signing-approvals/GetSigningRequestDetails?requestId=" + searchId;
                httpGet(_httpClient, acccessToken, url, function (response) {
                    var filteredData = response.data[0].requestor.includes(userAlias);
                    if (filteredData) {
                        setColumns(column);
                        setMyRequests(response.data);
                        setDefaultMyRequests(response.data);
                    }
                    else {
                        toast.error("RequestID: " + searchId + " isn't created by you. You can find requests created by others from home page top search feature");
                    }
                }, function (error) {
                    toast.error("RequestID: " + searchId + " not found");
                });
            }
            return [2 /*return*/];
        });
    }); };
    var GetAllRequestsByRequestor = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (loggedInUser != "" && acccessToken != "") {
                url = baseUrl + "/signing-approvals/GetAllRequestsByRequestor";
                httpGet(_httpClient, acccessToken, url, function (response) {
                    setColumns(column);
                    setMyRequests(response.data);
                    setDefaultMyRequests(response.data);
                    telemetryClient.trackTrace({
                        message: "Successfully retrived all Requests by Requestor",
                        properties: { 'Component Name': 'MyRequests', 'Method Name': 'GetAllRequestsByRequestor', },
                    });
                }, function (error) {
                    toast.error(error.data.Message);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyRequests', 'Method Name': 'GetAllRequestsByRequestor', },
                    });
                });
            }
            return [2 /*return*/];
        });
    }); };
    function _copyAndSort(item, columnKey, isSortedDescending) {
        var key = columnKey;
        return item.slice(0).sort(function (a, b) { return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1); });
    }
    var onSubmit = function (ev) {
        ev.preventDefault();
    };
    var createSigningRequestOnPanelSubmit = function (item) {
        dismissPanelForCreate();
    };
    var veiewRequestOnPanelSubmit = function (item) {
        dismissPanelToVeiewRequest();
    };
    var RequestAction = function (requestId, action) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (action == 'Cancel') {
                cancelSigningRequest(requestId, action);
            }
            return [2 /*return*/];
        });
    }); };
    var cancelSigningRequest = function (requestId, action) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            if (requestId != "" && action != "" && acccessToken != "") {
                url = baseUrl + "/signing-approvals/RequestAction?requestId=" + requestId + "&&approverAction=" + action;
                httpPut(_httpClient, acccessToken, url, function (response) {
                    toast.success("RequestID:  " + requestId + " has been Cancelled successfully");
                    Refresh();
                }, function (error) {
                    toast.error("Error while Cancelling RequestId:  " + requestId + " \" and error is : " + error.data);
                    telemetryClient.trackException({
                        exception: error.data.Message,
                        properties: { 'Component Name': 'MyRequests', 'Method Name': 'cancelSigningRequest', },
                    });
                }, JSON.stringify({}));
            }
            return [2 /*return*/];
        });
    }); };
    var onRenderDetailsHeader = function (props, defaultRender) {
        if (!props) {
            return null;
        }
        var onRenderColumnHeaderTooltip = function (tooltipHostProps) { return (React.createElement(TooltipHost, __assign({}, tooltipHostProps))); };
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(__assign(__assign({}, props), { onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip }))));
    };
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText = event.target.value;
            setSearchText(queryText);
            if (queryText) {
                var filteredData = filterData(queryText);
                setDefaultMyRequests(filteredData);
            }
            else {
                setDefaultMyRequests(myRequests);
            }
        }
        else {
            setSearchText('');
            setDefaultMyRequests(myRequests);
        }
    };
    var filterData = function (queryText) {
        return myRequests.filter(function (_items) {
            return (_items.requestID && _items.requestID.toString().toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.requestor && _items.requestor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.sponsor && _items.sponsor.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.approver && _items.approver.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.businessJustification && _items.businessJustification.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.productName && _items.productName.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingDetail && _items.signingDetail.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.signingType && _items.signingType.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.statusCode && _items.statusCode.toLowerCase().includes(queryText.trim().toLowerCase()));
        });
    };
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var onClickGetDataHandler = function (event) {
        var metricData = {
            name: 'Top Search',
        };
        var additionalProperties = { 'Component Name': 'MyRequests' };
        if (event.target.value.length > 0) {
            SearchResults(event.target.value);
            telemetryClient.trackMetric(metricData, additionalProperties);
        }
        else {
            toast.error("Please ensure Proper search criteria in Searchbox");
        }
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }),
            React.createElement("div", null,
                React.createElement(Stack, null,
                    React.createElement(Stack, { horizontal: true, horizontalAlign: "space-between" },
                        React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: tabLeftStackTokens },
                            React.createElement(Stack.Item, { grow: 1, styles: stackItemHeadingStyles },
                                React.createElement("h1", null, "My Requests"))),
                        React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: tabRighStackTokens },
                            React.createElement(Stack.Item, { grow: 1, styles: stackItemRefreshStyles },
                                React.createElement(SearchBox, { placeholder: "Filter", iconProps: filterIcon, styles: stackItemSearchBoxStyles, onChange: function (e) { return onSearchHandler(e); } }),
                                React.createElement(CommandBarButton, { iconProps: refresh, onClick: Refresh, style: { backgroundColor: 'transparent' } }, "Refresh"),
                                React.createElement(CommandBarButton, { iconProps: newRequest, onClick: navigateToNewRequestPage, style: { backgroundColor: 'transparent' } }, "New Request")))))),
            React.createElement("div", null,
                React.createElement(Panel, { headerText: "Create Signing Request", isOpen: isOpenForCreate, onDismiss: dismissPanelForCreate, isLightDismiss: true, type: PanelType.medium, closeButtonAriaLabel: "Close" },
                    React.createElement(CreateSigningRequest, { requestorAlias: requestorAliasData, onPanelSubmit: createSigningRequestOnPanelSubmit })),
                React.createElement(Panel, { headerText: "View My Request Details", isOpen: isOpenToVeiewRequest, onDismiss: dismissPanelToVeiewRequest, isLightDismiss: true, type: PanelType.medium, closeButtonAriaLabel: "Close" },
                    React.createElement(MyRequestDetails, { requestIdData: requestIdData, onPanelSubmit: veiewRequestOnPanelSubmit }))),
            defaultMyRequests.length > 0 ?
                React.createElement(ScrollablePane, { style: { height: '73vh', position: 'relative' } },
                    React.createElement(DetailsList, { compact: true, columns: columns, items: defaultMyRequests, layoutMode: DetailsListLayoutMode.justified, constrainMode: ConstrainMode.unconstrained, onRenderDetailsHeader: onRenderDetailsHeader, ariaLabel: "Value", ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selectionMode: SelectionMode.none }))
                : React.createElement("div", null, "You have no signing requests to show."),
            React.createElement(Dialog, { hidden: hideCancelReqDialog, onDismiss: toggleHideCancelReqDialog, dialogContentProps: cancelDialogContentProps, modalProps: modalProps, minWidth: 525 },
                React.createElement(DialogFooter, null,
                    React.createElement(PrimaryButton, { onClick: function () {
                            toggleHideCancelReqDialog();
                            RequestAction(requestId, "Cancel");
                        }, text: "Okay, Cancel Request" }),
                    React.createElement(DefaultButton, { onClick: toggleHideCancelReqDialog, text: "Don't Cancel" }))))));
};
export default ViewMyRequests;
