const appConfig = {
    Telemetry: {
        ServiceOffering: 'DES SWSecurity',
        ServiceLine: 'DES SWSecurity',
        Service: 'AutoSign',
        ComponentName: 'AutoSign',
        ComponentId: '',
        IctoId: 'AutoSign Prod Subscription',
        CorrelationKey: '',
        EnvironmentName: '__EnvironmentName__',
        InstrumentationKey: {
            development: '__InstrumentationKeyUI__',
            production: '__InstrumentationKeyUI__',
            test: '__InstrumentationKeyUI__',
            none: '',
        },
        TraceEnabled: true,
        ItExtVersion: 'npm:2.5.4-build0610',
    },
    AppSettings: {
        AppName: __APP_NAME__,
        AppVersion: __APP_VERSION__,
        APIUrl: __API_URL__,
        UIUrl: __BASE_URL__,
        APIMKey : '__APIMSubscriptionKey__',
        StorageAccountName: __STORAGE_ACCOUNT__,
        ClientApiScopes: __CLIENT_API_SCOPES__
    },
    Search: {
        ResultsPerPage: 20,
        NoOfCategories: 5,
        NoOfOptions: 4,
        EntitiesPerPage: 10,
    },
    Feedback: {
        development: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        production: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        test: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        none: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
    },
    StateManager: {
        MaxHistory: 3,
    },
};

module.exports = {
    appConfig,
};
