import { MSALV2Client } from '@employee-experience/core/lib/MSALV2Client';
import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { Shell } from '@employee-experience/shell/lib/Shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
var globals = require('./../config.js').globals;
var telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: 'Non-Production',
        ServiceOffering: 'Example Service Offering',
        ServiceLine: 'Example Service Line',
        Service: 'Example Service',
        ComponentName: 'Example Component',
        ComponentId: 'Example Id',
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});
var authClient = new MSALV2Client({
    auth: {
        clientId: __CLIENT_ID__,
        redirectUri: window.location.origin,
        authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47'
    },
}, telemetryClient);
var httpClient = new HttpClient(telemetryClient, authClient);
var graphClient = new GraphClient(httpClient);
var componentLoader = new ComponentLoader(telemetryClient, httpClient);
var reducerRegistry = new ReducerRegistry();
var appName = 'DemoApp';
var storeResult = new StoreBuilder(reducerRegistry, {})
    .configureLogger(__IS_DEVELOPMENT__)
    .configureSaga({ telemetryClient: telemetryClient, authClient: authClient, httpClient: httpClient, componentLoader: componentLoader, graphClient: graphClient, appName: appName })
    .build();
export var ShellWithStore = withStore(storeResult)(Shell);
