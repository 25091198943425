import { ComponentContext } from "@employee-experience/common";
import { Dropdown, IconButton, Label, Stack, TooltipHost } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getProducts } from "../../Services/SigningSubmissionApi";
import { Loading } from "../CommonUtilities/Loading";
import { stackItemRequestContentStyles, stackItemRequestHeaderStyles } from "../ManageSigning/Styles";
export function ProductDropdown(props) {
    var _a = useState([]), productOptions = _a[0], setProductOptions = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = React.useContext(ComponentContext), telemetryClient = _c.telemetryClient, authClient = _c.authClient;
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    useEffect(function () {
        var temp = [];
        getProducts(authClient).then(function (res) {
            res.data.map(function (product) {
                temp.push({
                    key: product.id,
                    text: product.name
                });
            });
            setProductOptions(temp);
        }).catch(function (e) {
            toast.error("Failed to load products", { toastId: 'failedToLoadProducts' });
            telemetryClient.trackException(e);
            setError(e);
            temp.push({ key: 0, text: 'Failed to load products' });
            setProductOptions(temp);
        });
    }, [props]);
    return (React.createElement(React.Fragment, null,
        productOptions.length > 0 &&
            (React.createElement(Stack, { horizontal: true },
                React.createElement(Stack.Item, { styles: stackItemRequestHeaderStyles },
                    React.createElement(Stack, { horizontal: true },
                        React.createElement(Label, null,
                            " ",
                            React.createElement("strong", null, " Products:")),
                        React.createElement(TooltipHost, { closeDelay: 500, content: "For products not public yet, choose FUTURE PRODUCT. You'll need to know the Signing Type code; contact wdshsm for your Signing Type code." },
                            React.createElement(IconButton, { title: 'Products', iconProps: { iconName: 'Info' } })))),
                React.createElement(Stack.Item, { styles: stackItemRequestContentStyles },
                    React.createElement(Dropdown, { "aria-label": "Products dropdown", styles: props.styles, options: productOptions, onChange: function (e, o) { return props.onChange(o); } })))),
        productOptions.length <= 0 && !error && React.createElement(Loading, null)));
}
