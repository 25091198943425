import { mergeStyleSets } from "@fluentui/react";
export var controlClass = mergeStyleSets({
    control: {
        marginBottom: '10px',
        marginRight: '30px',
        maxWidth: '300px',
    },
    searchStyles: {
        marginTop: '30px',
    },
    submitStyles: {
        marginTop: '20px',
        marginLeft: '10px',
    },
    shipToSpanStyles: {
        display: 'block',
        wordwrap: 'break-word'
    },
    shipToToolTipStyles: {
        overflow: 'hidden',
        textoverflow: 'ellipsis'
    }
});
export var hostStyles = {
    root: { display: 'block' },
};
export var controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '150px',
    },
};
export var stackTokens = { childrenGap: 50 };
export var dropdownStyles = {
    dropdown: { width: 200 },
};
